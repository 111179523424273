.toast.bg-danger .toast-body {
    background-color: rgba(246, 138, 138, 1);
}

.toast.bg-info .toast-body {
    background-color: rgba(142, 198, 255, 1);
}

.toast .toast-header {
    width: 100%;
    position: relative;
}

.toast .toast-header > button {
    position: absolute;
    right: 14px;
}
